<template>
  <div>
    <PageTitle>Scrims Stats</PageTitle>
    <ApiLoadingController ref="loadingController" :fetch="getScrims">
      <template>
        <AppTabs>
          <template v-for="m in maps">
            <AppTab :key="m" :title="m">
              <div>
                <SelectedMapStats :map-name="m" :data="scrims[m]" />
              </div>
            </AppTab>
          </template>
        </AppTabs>
      </template>
    </ApiLoadingController>
  </div>
</template>

<script>
import { format } from 'date-fns'

import { getMatches, getMatchExtraData } from '@/api/val-scrims'
import { parseOWScrims } from '@/utils/processScrimStatData'

import ApiLoadingController from '../components/controllers/ApiLoadingController.vue'
import PageTitle from '../components/generic/PageTitle.vue'
import AppTab from '../components/generic/Tab.vue'
import AppTabs from '../components/generic/Tabs.vue'
import SelectedMapStats from '../components/ScrimStats/SelectedMapStats.vue'

export default {
  name: 'ScrimStats',
  components: {
    ApiLoadingController,
    PageTitle,
    SelectedMapStats,
    AppTab,
    AppTabs,
  },
  data() {
    return {
      scrims: null,
      maps: [],
    }
  },
  computed: {},
  methods: {
    async getScrims() {
      const { items: scrims } = await getMatches()
      const temp_collection = []

      if (scrims?.length && scrims.length > 0) {
        for (const scrim of scrims) {
          if (scrim.id) {
            const data = await this.getScrimData(scrim.id)
            if (data !== {})
              temp_collection.push({
                ...data,
                scrim_date: format(new Date(scrim.created_at * 1000), 'MMM d, yyyy'),
              })
          }
        }
      }

      if (temp_collection.length === 0) return []

      const parsed_stats = parseOWScrims(temp_collection)
      this.scrims = parsed_stats
      this.maps = Object.keys(this.scrims).sort()

      return this.scrims
      // return Object.freeze(
      //   scrims.map(scrim =>
      //     Object.freeze({
      //       ...scrim,
      //       created_at: new Date(scrim.created_at * 1000),
      //       teams: [
      //         {
      //           name: 'Ally',
      //           team_side: 'n/a side',
      //           rounds_won: scrim.ally_score,
      //           rouns_played: scrim.total_rounds,
      //           rounds_first_half_won: scrim.ally_score_first_half,
      //           rounds_second_half_won: scrim.ally_score_second_half,
      //           rounds_overtime_won: scrim.ally_score_overtime,
      //           is_self_team: true,
      //           id: 'self',
      //           win: scrim.ally_score > scrim.enemy_score,
      //           agent_ids: scrim.ally_players?.map(player => player.agent_id),
      //         },
      //         {
      //           name: 'Enemy',
      //           team_side: 'n/a side',
      //           rounds_won: scrim.enemy_score,
      //           rouns_played: scrim.total_rounds,
      //           rounds_first_half_won: scrim.enemy_score_first_half,
      //           rounds_second_half_won: scrim.enemy_score_second_half,
      //           rounds_overtime_won: scrim.enemy_score_overtime,
      //           is_self_team: false,
      //           id: 'enemy',
      //           win: scrim.ally_score < scrim.enemy_score,
      //           agent_ids: scrim.enemy_players?.map(player => player.agent_id),
      //         },
      //       ],
      //     })
      //   )
      // )
    },
    async getScrimData(id) {
      const extraData = await getMatchExtraData(id)
      if (extraData?.finalData) return extraData.finalData
      return {}
    },
  },
}
</script>

<style scoped lang="scss">
.mapTabs {
  display: flex;
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid gray;
}

.tab {
  background-color: #404040;
  border: none;
  color: white;
  height: 32px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
}

.tab:focus {
  outline: none;
}

.active {
  border-bottom: 4px solid white;
  font-weight: 700;
  background-color: #181818;
}
</style>
